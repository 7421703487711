<template>
  <div>
    <PageLoader />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pb-4 px-3>
      <v-flex xs12 text-left>
        <span class="homeTop"> Contact </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12 md12>
                <v-text-field
                  class="homeText"
                  dense
                  outlined
                  v-model="contact.top.text"
                  label="Title"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <span class="homeText">Select Title Color</span>
                <v-color-picker
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="contact.top.textColor"
                ></v-color-picker>
              </v-flex>
              <v-flex xs12 sm6 md6 pb-5>
                <ImageComp
                  @stepper="winStepper"
                  :singleImage="contact.top.image"
                  :height="'1800'"
                  :width="'4000'"
                  :heading="'Upload Top Image'"
                  :componentType="'topimage'"
                />
              </v-flex>
              <v-flex xs12>
                <span class="homeText"> Address</span>
                <div id="app">
                  <vue-editor v-model="contact.address"></vue-editor>
                </div>
              </v-flex>
              <v-flex xs12>
                <span class="homeText"> Emails</span>
                <div id="app">
                  <vue-editor v-model="contact.emails"></vue-editor>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="black"
                  light
                  :ripple="false"
                  depressed
                  @click="add"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";
import ImageComp from "@/components/Common/singleImage";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    ImageComp,
    VueEditor,
  },
  data() {
    return {
      contact: {
        top: { text: null, textColor: null },
        address: null,
        emails: null,
      },
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      producttopimagefile: null,
      productrightimagefile: null,
      imageFiles: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "topimage") {
        this.topimageFile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.contact.top.text) {
        this.msg = "Please Provide Text";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    getList() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/contact/page/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.contact = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      // this.contact.top["textColor"] = this.contact.top.textColor.hexa;
      axios({
        method: "post",
        url: "/contact/page/set",
        data: this.contact,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Successully";
            if (this.topimageFile) {
              this.uploadImage();
            }
            this.showSnackBar = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadImage() {
      this.appLoading = true;
      this.formDataCover.append("image", this.topimageFile);
      this.formDataCover.append("type", "about us");
      axios({
        method: "POST",
        url: "/contact/page/image",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formDataCover = new FormData();
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.topimageFile = null;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>